var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "product-slide" } },
    [
      _c("slide-heading", {
        attrs: { heading: _vm.slide.heading, "tool-tip": _vm.slide.tool_tip },
      }),
      _c("slide-image-and-description", {
        attrs: { slide: _vm.slide, "allow-images": true },
      }),
      _c("product-options-confirm-modal", {
        attrs: { bus: _vm.bus, loading: _vm.loading },
        on: {
          complete: _vm.productOptionConfirmed,
          productAdded: _vm.productAdded,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }